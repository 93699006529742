.forcaSenha1 {
  height: 11px;
  width: 23%;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: #bbb;
}
.forcaSenha2 {
  height: 11px;
  width: 23%;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: #bbb;
}
.forcaSenha3 {
  height: 11px;
  width: 23%;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: #bbb;
}

.forcaSenha4 {
  height: 11px;
  width: 23%;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: #bbb;
}
.forcaSenhaAtiva {
  border: solid 5px rgb(18, 166, 1);
  box-shadow: 0 0 10px rgb(0, 255, 17);
  background-color:white
}
