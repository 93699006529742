@import '../../styles/variaveis';

footer {
    background-color: $cor-dark-2;
    color: $cor-dark;
    padding: 20px 0 10px 0;
    font-size: 12px;

    p {
        font-size: 12px;
        a{
            color: $cor-dark; 
            &:hover {
                color: $cor-light;
            }
        }

    }

    h3 {
        font-size: 14px;
        margin-bottom: 14px;
    }

    .footerSocialMedia {
        margin-bottom: 20px;
        img {
            margin-right: 10px;
            max-height: 40px;
        }
    }

    .footerContent {
        display: flex;
        align-items: flex-start;
        text-align: justify;
        font-size: 10px;
        img {
            margin: 0 20px 20px 0;
        }
    }
    
    ul {
        border-top: 1px solid $cor-gray-50;
        width: 100%;
        margin: 0px;
        padding: 0px;
        li {
            list-style: none;
            text-align: center;
            font-size: 12px;
            padding: 10px 20px;
            float:left;

            &:first-child {
                padding: 10px 20px 10px 10px;
            }
            &:last-child {
                padding: 10px 10px 10px 20px;
            }

            a {
                font-size: 12px;
                color: $cor-dark;
                text-decoration: none;
                float:left;
                &:hover {
                    color: $cor-dark;
                }
            }
        }
    }

}

.link-chev:hover {
    color: #191ba788 !important;
}