@import '../../styles/variaveis';

.FaqTitle {
    background-color: $cor-principal-light;
    color: #ffffff;

    .FaqTitleContent {
        display: flex;
        align-items: stretch;

        button {
            background-color: $cor-principal;
            border: 0px;
            outline: 0px;
            color: $cor-light;
            padding: 30px 20px;
            text-transform: uppercase;
            font-size: 14px;
            img {
                width: 20px;
                margin-right: 10px;
            }
        }

        div {
            display: flex;
            align-items: center;
            min-height: 100px;

            h2 {
                font-size: 26px;
                margin: 0px;
                font-weight: 300;
                padding: 20px 40px;
                strong {
                    font-weight: bold;
                }
            }
        }

    }
}



@media (max-width: $brackpoint-md) {

    .FaqTitle {
        .FaqTitleContent {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            button {
                margin: 20px 0 0 0;
                padding: 10px 20px;
                display: block;
                text-transform: uppercase;
                font-size: 14px;
            }

        }
    }
}