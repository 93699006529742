@import '../../styles/variaveis';

.SectionCallAction {
    background-color: $cor-gray-60;
    color: $cor-light;
    padding: 30px 0;
    
    a {
        color: $cor-light;
        text-decoration: none;
        &:hover {
            color: $cor-light;
        }
    }
}