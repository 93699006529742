@import 'variaveis';
@import 'forms';
@import 'buttom';
@import 'table';


body {
    background-color: $cor-light;
    margin: 0;
    font-family: 'Roboto', sans-serif;
}

h1, h2, h3, h4, h5, h6, a, p, span, ul, li{
    color: $cor-dark-1;
   }
   .dark, label{
     color: $cor-dark !important;
   }


a {
    color: $cor-dark;
    text-decoration: underline;
    &.link {
        text-decoration: none;
    }
}
h1 {
    font-weight: 400;
}

p {
    font-size: 19px;
    overflow-wrap: break-word;
    &.big {
        font-size: 22px;
        font-weight: 100;
    }
    &.bold {
        font-weight: 100;
    }
}


h2 {
    font-weight: 400;
    margin: 10px 0 40px 0px;
    font-size: 40px; 
}
h3 {
    font-size: 30px;
    font-weight: 500;
}
.italic {
    font-style: italic;
}
img {
    &.responsive {
        max-width: 100%;
    }
    &.maxW300 {
        max-width: 300px;
    }
}
.contentDefault {
    padding-top: 40px;
    padding-bottom: 60px;
}
.contentDefaultInicio {
    padding-top: 40px;
    padding-bottom: 28px;
}
.center {
    text-align: center;
}
.mAuto {
    margin: auto;
}

.titleEspecial {
    text-transform: uppercase;
    font-weight: 100;
}

.titleEspecialSorteio {
    text-transform: none;
   font-weight: 100;
   margin: -23px 0 40px 0px;
   font-size: 35px;
}

.titleResultadoSorteio {
    text-transform: none;
   font-weight: 100;
   margin: -23px 0 40px 0px;
   font-size: 32px;
}

.alert.info {
    text-align: center;
    background-color: #707070;
    color : #fff;

    a {
        color : #fff;
    }
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// flex
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

.flex {
    display: flex;
}
.vertAlignFlex {
    align-items: center;
}
.spaceBetween {
    justify-content: space-between !important;
}
.justifyCenter {
    justify-content: center !important;
}
.horiAlignFlex {
    justify-content: space-around !important;
}
.flexDC {
    flex-direction: column;
}
.fixedBottom {
    position: fixed;
    width: 100%;
    bottom: 0px;
}
.fixedTop {
    position: fixed;
    width: 100%;
    top: 0px;
}

.flexLeft {
    display: flex;
}
.column {
    flex-direction:column
}
.align-left {
    float: left
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// flex
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////





/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// modal
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
.linhaAmarela{

    border-top: 2px solid #E6AC39 !important;
    margin-left: -66px;
    width: 115%;
}
.linhaAmarelaSorteio{

    border-top: 2px solid #E6AC39 !important;
    margin-left: -61px;
    width: 125%;
}
.NumSorteio{
    font-size: 45px;
    font-weight: 100;
}
.titulo-modal{
    letter-spacing: -0.2px;
    color: #3F3F3F;
    opacity: 1;
    font-size: 25px;
    font-family: 'Roboto';
    font-weight: 500;
    margin-top: 30px;
}
.titulo1-modal{
    letter-spacing: -0.2px;
    color: #3F3F3F;
    opacity: 1;
    font-size: 40px;
    font-family: 'Roboto';
    font-weight: 500;
    margin-top: 30px;
}
.titulo2-modal{
    font-size: 24px;
    margin-top: 30px;
}
.MuiDialogTitle-root { 
    text-align: center;
    h2 {
        margin: 0px;
        font-size: 30px !important;
    }
    button {
        position: absolute;
        top: 4px;
        right: -50px;
        color: white;
    }
}
.MuiDialog-paper {
    padding: 0px;
    margin: 50px !important;
    background-color: #FFF !important;
    overflow-y: unset !important;
}
.MuiDialogContent-root {
    padding: 30px 60px !important;
}

#alert-dialog-title > h2 > button > span.MuiIconButton-label {
    color: white !important;
}

.modal-sorteio{
    max-width: 630px !important;
    height: 500px !important;
}
.titleEspecialSorteio {
    text-transform: none;
   font-weight: 100;
   margin: -23px 0 40px 0px;
}
.MuiDialog-paperWidthMd {
    max-width: 960px;
    width: 90%;
}
.tamanho-modal {
    max-width: 630px !important;
    height: 500px !important;
    width: 630px !important;
}

.tituloModal{
    font-weight: 100;
    font-size: 35px;
}

.title1seuSorteio{
    margin-top: 10px;
    font-size: 17px;
}

.title2seuSorteio{
    margin-top: 10px;
    font-weight: bold;
    font-size: 18px;
}

.espaço-modal{
    margin-top: 10px;
}

.titleseuSorteio {
    text-transform: none;
    font-weight: 100;
    margin: 10px 0 16px 0px;
}

.cadastroLocalizado{
    font-weight: 400;
    font-size: 28px;
    margin-top: -5px;
}
@media (max-width: $brackpoint-sm) {
    .MuiDialogContent-root {
        padding: 20px 20px !important;
    }
    .MuiDialogTitle-root {
        padding:2px !important;
        h2 {
            text-align: center;
        }
        button {
            position: relative;
            right: 0px;
            color: black;
        }
    }
}

.modal-sorteio{
    max-width: 660px !important;
}


/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// modal
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// diagramacao
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


code {font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;}

.wrarpText { overflow-wrap: break-word;}

.m0 { margin: 0px !important;}

.m5 {margin: 5px !important;}

.m10 { margin: 10px !important;}

.m20 { margin: 20px !important;}

.m30 { margin: 30px !important;}
.mt10 { margin-top: 10px !important;}
.mt20 { margin-top: 20px !important;}
.mt30 { margin-top: 40px !important;}

.mb5 { margin-bottom: 5px !important;}
.mb10 { margin-bottom: 10px !important;}

.mb20 {margin-bottom: 20px !important;}

.mb30 { margin-bottom: 30px !important;}
.mb40 { margin-bottom: 40px !important;}
.mb120 { margin-bottom: 120px !important;}

.ml10 { margin-left: 10px !important;}

.ml20 { margin-left: 20px !important;}

.ml30 { margin-left: 30px !important;}
.mr4 {margin-right: 4px !important;}
.mr5 { margin-right: 5px !important;}
.mr8 { margin-right: 8px !important;}
.mr10 { margin-right: 10px !important;}

.mr20 {margin-right: 20px !important;}

.mr30 {margin-right: 30px !important;}

.pad0 {padding: 0px !important;}

.pad5 {padding: 5px !important;}

.pad10 {padding: 10px !important;}

.pad20 {padding: 20px !important;}

.padL0 {padding-left: 0px !important;}

.padL5 {padding-left: 5px !important;}

.padL10 {padding-left: 10px !important;}

.padL20 {padding-left: 20px !important;}
.padL40 {padding-left: 40px !important;}

.padR0 {padding-right: 0px !important;}

.padR5 {padding-right: 5px !important;}

.padR10 {padding-right: 10px !important;}

.padR20 {padding-right: 20px !important;}
.padR40 {padding-right: 40px !important;}

.padT0 {padding-top: 0px !important;}

.absolute {position: absolute !important}

.pointer{
    cursor: pointer;
}
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// diagramacao
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
