@import '../../styles/variaveis';

.loadPage {
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 9999999;
}

.MuiLinearProgress-barColorPrimary {
    background-color: $cor-principal !important;
}
.MuiLinearProgress-colorPrimary {
    background-color: $cor-principal-light-2 !important;
}
.MuiLinearProgress-colorPrimary {
    height: 4px !important;
}
