@import '../../styles/variaveis';

.bgMainFeature {
    text-align: center;
    background-color: $cor-dark-2;
    color: #ffffff;
    img {
        margin: auto;
        width: 100%;
    }
}

.textDeco{ 
    text-Decoration: none !important;
    color: white !important; 
}

.bgFeature {
    padding: 40px 0px ;
    background-color: #a5a5a5;
    color: #ffffff;
}

.titleHome {
    text-align: left;
    margin-top: 30px;
    span {
        border: 1px solid $cor-principal;
        display: block;
        height: 10px;
        margin-bottom: 40px;
        width: 70%;
    }
}

.Redirecionar {
    background-color: #E6AC39;
    width: 300px;
    height: 40px;
    border-radius: 8px;
    text-align: center;
    padding-top: 6px;
    font-family: "Roboto", "Helvetica";
    font-size: 18px;
    font-weight: bold;
    margin: auto;
    margin-bottom: 60px;
}

.bgFeature2 {
    padding: 12px 0px;
    background: linear-gradient(to right, #4C4C4C 0%, #4C4C4C 0%, #4C4C4C 55%, #3F3F3F 51%);
    color: #ffffff;
}

.bgFeature3 {
    padding: 12px 0px;
    background: linear-gradient(to right, #4C4C4C 0%, #4C4C4C 0%, #4C4C4C 59.5%, #3F3F3F 51%);
    color: #ffffff;
}

.etapas {
    color: $cor-dark !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;
    
}

.etapa {
    border-radius: 25px;
    background: #f3f3f3;
    margin: 10px;
    padding: 20px;
    text-align: left;
    h2 {
        font-size: 100px;
        margin-top: -85px;
        position: absolute;
        color: #ffffff00;
        -webkit-text-stroke-width: 1px; /* largura da borda */
        -webkit-text-stroke-color: #E6AC39; /* cor da borda */
        font-family: fantasy;
    }
    h4 {
        margin-top: 30px;
        color: #E6AC39;
        text-transform: uppercase;
    }
    p {
        font-size: 16px;
        line-height: 1.5;
        font-weight: 600;
    }
}
