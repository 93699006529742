.shellBoxIcon {
  width: 70px;
  margin-left: 30px;
}
.bgFeature {
  padding: 40px 0px;
  background-color: #1a1a1a;
  color: #ffffff;
}
.faixaTelefone {
  width: 100%;
  text-align: center;
  color: #fff;
  background-color: #888;
  font-weight: 500;
  font-size: 22px;
  padding: 5px 0;
}
.texto-aqui {
  color: #fff !important;
}
.btn-gray {
  color: #fff;

  background-color: #888 !important;
}

.titulo-inicial {
  font-weight: 400;
  margin: 10px 0 40px 0px;
  font-size: 40px;
}

@media screen and (max-width: 720px) {
  .titulo-inicial {
    font-size: 35px !important;
  }
}

.modalFimCampanha {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background-color: #2a2a2a94;
}
.conteudoModalFimCampanha {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 20px;
  background: #ffffff;
  padding: 30px;
}
.fecharModalCampanha {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
}


.numero-da-sorte {
  border: 3px solid #aaa;
  border-radius: 6px;
  margin-top: 30px;
  margin-bottom: 30px;
  span {
    font-size: 50px !important;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 30px;
    color: #E6AC39;
  }
}

.numero-da-sorte-nao-encontrado {
  margin-top: 30px;
  margin-bottom: 30px;
  span {
    font-size: 50px !important;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 30px;
    color: #E6AC39;
  }
}