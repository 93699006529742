@import '../../styles/variaveis';

.faq { 
    .pergunta {
        color: $cor-dark-1;
        padding: 8px 0px 8px 0px;
        margin-top: 6px;
        border-radius: 0px;
        cursor: pointer;
        transition: 0.5s all;
        font-size: 22px;
        border-bottom: 2px solid $cor-dark-3;
    }
    .arrow {
        float: right;
        position: absolute;
        right: 20px;
        color: $cor-principal-light;
    }
    .resposta {
        display: block; 
        opacity: 0;
        height: 0px;
        padding: 10px 0px;
        color: $cor-dark-3;
        a{
            color: $cor-dark-3;
            &:hover{
                color: #aaa;
                
                }
        }
    }
    .active {
        .pergunta {
            border-radius: 0px;
        }
        .resposta {
            height: auto;
            padding: 20px 0px;
            display: block;
            opacity: 0.8;
        }
    }
}
.qrCode{
    width: 10px;
}