@import '../../styles/variaveis';

p {
    margin-bottom: 8px !important;
}

.statusRecall {
    display: flex;
    align-items: center;

    img {
        width: 60px;
        margin-right: 20px;
    }

    p {
        font-size: 30px;
        font-weight: 900;
        text-transform: uppercase;
    }
    
    &.red {
        color: red;
    }
    &.green {
        color: green;
    }
}