@import 'variaveis';

input, input.form-control, select{
    padding: 10px 20px;
    width: 100%;
    border-radius: 0px;
    background-color: $cor-gray-80 ;
    border: 2px solid $cor-gray-50;
    color: #1b1b1b !important;
}
input::placeholder{
    
    color: $cor-gray-40 !important;
     
}

.form-control:focus {
    background-color: $cor-gray-50; 
    color: $cor-dark !important ;
    border-color: #aaa;
    outline: 0;
    box-shadow: 0 0 0 0.2rem $cor-gray-20;
}

label {
    font-size: 14px;
}

.form-control-lg {
    height: calc(1.5em + 2rem + 2px);
}

.checkboxContainer {
    display: flex;
    align-items: flex-start;
    p { 
        margin: 10px 0;
        font-size: 14px;
    }
}


textarea {
    border-radius: 4px;
    border: 1px solid $cor-gray-50;
    padding: 10px;
}


