@import 'variaveis';


.btn {
    border-radius: 0px;
}

.btn-info {
    background-color: $cor-gray-40;
    border-color: $cor-gray-40;
    &:focus, &:hover {
        background-color: $cor-gray-60;
        box-shadow: none;
    }
}

.btn-info:not(:disabled):not(.disabled).active:focus, 
.btn-info:not(:disabled):not(.disabled):active:focus, 
.show>.btn-info.dropdown-toggle:focus {
    box-shadow: none;
}
.btn-info:not(:disabled):not(.disabled).active, 
.btn-info:not(:disabled):not(.disabled):active, 
.show>.btn-info.dropdown-toggle {
    color: #fff;
    background-color: $cor-gray-40;
    border-color: $cor-gray-40;
}

.btn-primary {
    background-color: $cor-principal-light-2;
    border-color: $cor-principal-light-2; 
    border-width: 1px; 
    font-weight: bold;
    &.btn-lg {
        font-size: 16px;
        padding: 14px 40px;
    }
    &:focus, &:hover {
        background-color: $cor-principal-light;
        border-color: $cor-principal-light; 
        box-shadow: none;
    }
}

.btn-primary:not(:disabled):not(.disabled).active:focus, 
.btn-primary:not(:disabled):not(.disabled):active:focus, 
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none;
}
.btn-primary:not(:disabled):not(.disabled).active, 
.btn-primary:not(:disabled):not(.disabled):active, 
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: $cor-principal-light-2;
    border-color: $cor-principal-light-2; 
}

.btn-outline-primary   {
    border-color: $cor-principal-light-2; 
    color: $cor-dark; 
    border-width: 2px; 
    font-weight: bold;
    &.btn-lg {
        font-size: 22px;
        padding: 14px 50px;
    }
    &:hover, &:hover:focus {
        background-color: transparent;
        color: $cor-dark-1;
        border-color: $cor-principal-light;
        box-shadow: none;
    }
    &:focus {
      
        box-shadow: none;
    }
    &:active {
        
        border-color: $cor-light-2 !important; 
        color: $cor-light !important;  
       
    }
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus, 
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, 
.show>.btn-outline-primary.dropdown-toggle:focus {
    box-shadow: none;
}
.btn-outline-primary:not(:disabled):not(.disabled).active, 
.btn-outline-primary:not(:disabled):not(.disabled):active, 
.show>.btn-outline-primary.dropdown-toggle {
    background-color: transparent;
    border-color: $cor-principal-light-2; 
    color: $cor-dark; 
}

.btn-link {
    color: $cor-dark;
    text-decoration: underline;
    font-weight: bold;

    img {
        margin-right: 10px;
        width: 20px;
        margin-bottom: 6px; 
    }

    &:hover {
        color: $cor-dark;
        background-color: $cor-gray-20;
    }
}